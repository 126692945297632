<template>
    <div>
        <el-main>
            <el-button type="primary" style="margin-bottom: 10px" size="small" @click="add">+添加合伙人</el-button>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="用户账号：">
                    <el-input size="small" type="number" v-model="searchForm.u_mobile" placeholder="请输入用户账号"></el-input>
                </el-form-item>
                <el-form-item label="子公司名称：">
                    <el-input size="small" v-model="searchForm.subsidiary_name" placeholder="请输入子公司名称"></el-input>
                </el-form-item>
                <el-form-item label="合伙人姓名：">
                    <el-input size="small" v-model="searchForm.partner_name" placeholder="请输入合伙人姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <el-input size="small" type="number" v-model="searchForm.mobile" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="添加时间：">
                    <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="u_mobile" label="用户账号" width="110" align="center"></el-table-column>
                <el-table-column prop="type" label="合伙人类型" width="90" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.type == 1 ? '个体' : row.type == 2 ? '企业' : '--' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="subsidiary_name" label="子公司名称" width="180" align="center"></el-table-column>
                <el-table-column prop="partner_name" label="合伙人姓名" width="90" align="center"></el-table-column>
                <el-table-column prop="mobile" label="联系电话" width="110" align="center"></el-table-column>
                <el-table-column prop="identity_card_front" label="身份证正面" width="100" align="center">
                    <template v-slot="{ row }">
                        <div class="demo-image__preview">
                            <el-image style="width: 100px; height: 50px" :src="row.identity_card_front[0]"
                                :preview-src-list="row.identity_card_front"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="identity_card_verso" label="身份证背面" width="100" align="center">
                    <template v-slot="{ row }">
                        <div class="demo-image__preview">
                            <el-image style="width: 100px; height: 50px" :src="row.identity_card_front[0]"
                                :preview-src-list="row.identity_card_front"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="area" label="所在地址" width="300" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.province_name }}{{ row.city_name }}{{ row.area_name }}{{ row.address }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="business_license" label="营业执照" width="100" align="center">
                    <template v-slot="{ row }">
                        <div class="demo-image__preview">
                            <div v-if="row.type == 1">--</div>
                            <el-image v-if="row.type == 2" style="width: 100px; height: 50px" :src="row.business_license[0]"
                                :preview-src-list="row.business_license"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="is_shop" label="是否有门店" align="center" width="100">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.is_shop == -1 ? '否' : row.is_shop == 1 ? '是' : '--' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="gate_picture" label="门头照片" width="100" align="center">
                    <template v-slot="{ row }">
                        <div class="demo-image__preview">
                            <div v-if="row.is_shop == -1">--</div>
                            <el-image v-if="row.is_shop == 1" style="width: 100px; height: 50px" :src="row.gate_picture[0]"
                                :preview-src-list="row.gate_picture"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="interior_picture" label="店内照片" width="100" align="center">
                    <template v-slot="{ row }">
                        <div class="demo-image__preview">
                            <div v-if="row.is_shop == -1">--</div>
                            <el-image v-if="row.is_shop == 1" style="width: 100px; height: 50px"
                                :src="row.interior_picture[0]" :preview-src-list="row.interior_picture"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="service_order_amount" label="服务订单总金额" width="120" align="center">
                    <template v-slot="{ row }">
                        <span>
                            ￥{{ row.service_order_amount }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="service_order_sum" label="服务订单总数" width="110" align="center"></el-table-column>
                <el-table-column prop="service_total_amount" label="服务收益总金额" width="120" align="center">
                    <template v-slot="{ row }">
                        <span>
                            ￥{{ row.service_total_amount }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="添加时间" width="200" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
                <el-table-column label="操作" width="150" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
        <el-dialog :title="type ? '编辑合伙人' : '添加合伙人'" :visible.sync="showAdd" width="30%" :before-close="handleClose">
            <el-form ref="form" class="add" :model="addForm" :rules="rules" label-width="110px">
                <el-form-item label="选择用户：" required="true">
                    <div style="display: flex">
                        <el-input v-model="addForm.user_id" disabled></el-input>
                        <el-button type="primary" @click="showUserList = !0" v-if="type == 0">选择</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="合伙人类型：" required="true">
                    <el-radio-group v-model="addForm.type">
                        <el-radio :label="1">个体</el-radio>
                        <el-radio :label="2">企业</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="选择子公司：" required="true">
                    <el-select v-model="addForm.subsidiary_id" placeholder="请选择或搜索" clearable filterable
                        style="width: 100%">
                        <el-option v-for="item in subsidiaryList" :key="item.id" :label="item.subsidiary_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="合伙人姓名：" required="true">
                    <el-input v-model="addForm.partner_name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：" required="true">
                    <el-input v-model="addForm.mobile" type="number"></el-input>
                </el-form-item>
                <el-form-item label="身份证正面：" required="true">
                    <ReadyUploadSource @getSource="val => (addForm.identity_card_front = val.path)"
                        :path="addForm.identity_card_front" @removeThis="() => (addForm.identity_card_front = '')">
                    </ReadyUploadSource>
                </el-form-item>
                <el-form-item label="身份证背面：" required="true">
                    <ReadyUploadSource @getSource="val => (addForm.identity_card_verso = val.path)"
                        :path="addForm.identity_card_verso" @removeThis="() => (addForm.identity_card_verso = '')">
                    </ReadyUploadSource>
                </el-form-item>
                <el-form-item label="合伙人区域：" required="true">
                    <div class="selectBox">
                        <el-select v-model="province_name" placeholder="请选择" @change="val => sonArea(0, val)">
                            <el-option v-for="(item, index) in area" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                        <el-select v-model="city_name" placeholder="请选择" @change="val => sonArea(1, val)">
                            <el-option v-for="(item, index) in cityArr" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                        <el-select v-model="area_name" placeholder="请选择" @change="val => sonArea(2, val)">
                            <el-option v-for="(item, index) in areaArr" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="所在地址：" required="true">
                    <el-input v-model="addForm.address"></el-input>
                </el-form-item>
                <el-form-item label="营业执照：" v-if="addForm.type == 2">
                    <ReadyUploadSource @getSource="val => (addForm.business_license = val.path)"
                        :path="addForm.business_license" @removeThis="() => (addForm.business_license = '')">
                    </ReadyUploadSource>
                </el-form-item>
                <el-form-item label="是否有门店：" required="true">
                    <el-radio-group v-model="addForm.is_shop">
                        <el-radio :label="-1">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="门头照片：" v-if="addForm.is_shop == 1">
                    <ReadyUploadSource @getSource="val => (addForm.gate_picture = val.path)" :path="addForm.gate_picture"
                        @removeThis="() => (addForm.gate_picture = '')"></ReadyUploadSource>
                </el-form-item>
                <el-form-item label="店内照片：" v-if="addForm.is_shop == 1">
                    <ReadyUploadSource @getSource="val => (addForm.interior_picture = val.path)"
                        :path="addForm.interior_picture" @removeThis="() => (addForm.interior_picture = '')">
                    </ReadyUploadSource>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAdd = !1">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择用户 -->
        <el-dialog title="选择用户" :visible.sync="showUserList" width="54%">
            <el-form class="el-form-search" label-width="110px">
                <el-form-item label="用户名称：">
                    <el-input size="small" placeholder="请输入用户昵称" v-model="user_form.name"></el-input>
                </el-form-item>
                <el-form-item label="用户手机：">
                    <el-input size="small" placeholder="请输入用户电话" v-model="user_form.mobile"></el-input>
                </el-form-item>
                <el-form-item label=" " label-width="20px">
                    <el-button type="primary" size="small" @click="searchUser">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="user_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="id" label="用户ID" align="center"></el-table-column>
                <el-table-column prop="avatar" label="用户头像" align="center">
                    <template slot-scope="scope">
                        <el-image :src="scope.row.avatar" class="avatar"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="用户名称" align="center"></el-table-column>
                <el-table-column prop="mobile" label="用户手机" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="chooseUser(scope.row.id)" type="text" size="small">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="user_total_number" :page="user_form.page" :pageNum="user_form.rows"
                @updatePageNum="updateDataUser"></Paging>
        </el-dialog>
    </div>
</template>
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { matchDataAssign } from '@/util/util';
import { getDateformat } from '@/util/getDate';
import ReadyUploadSource from '@/components/readyUploadSource';

export default {
    components: {
        Paging,
        ReadyUploadSource
    },
    data () {
        return {
            showUserList: !1,
            user_form: {
                page: 1,
                rows: 5,
                name: "",
                mobile: '',
            },
            user_list: [],
            user_total_number: 0,
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                u_mobile: '',
                subsidiary_name: '',
                partner_name: '',
                mobile: '',
                start_time: '',
                end_time: '',
            },
            subsidiaryList: [],
            showAdd: !1,
            type: 0,
            id: '',
            addForm: {
                user_id: '',
                type: 1,
                subsidiary_id: '',
                partner_name: '',
                mobile: '',
                identity_card_front: '',
                identity_card_verso: '',
                province_id: '',
                city_id: '',
                area_id: '',
                address: '',
                business_license: '',
                is_shop: -1,
                gate_picture: '',
                interior_picture: '',
            },
            province_name: '',
            city_name: '',
            area_name: '',
            // 省级数据
            area: [],
            // 市级数据
            cityArr: [],
            // 区级数据
            areaArr: [],
            flag: false,
        };
    },
    created () {
        this.getUserList();
        this.getList();
        this.getSubsidiaryList();
        this.getArea();
    },
    methods: {
        getUserList () {
            this.$axios.post(this.$api.repair.Partner.userList, this.user_form).then(res => {
                if (res.code == 0) {
                    this.user_list = res.result.list
                    this.user_total_number = res.result.total
                }
            });
        },
        updateDataUser (val, status) {
            if (status == 0) {
                this.user_form.rows = val;
                this.getUserList();
            } else {
                this.user_form.page = val;
                this.getUserList();
            }
        },
        chooseUser (id) {
            this.addForm.user_id = id;
            this.showUserList = !1;
        },
        getSubsidiaryList () {
            this.$axios.get(this.$api.repair.Subsidiary.lists).then(res => {
                if (res.code == 0) {
                    this.subsidiaryList = res.result;
                }
            });
        },
        // 获取省市区信息
        getArea () {
            this.$axios.get(this.$api.shopDiy.areaList, { tree: 1, level: 2 }).then(res => {
                if (res.code == 0) {
                    this.area = res.result.list;
                    this.flag = true;
                }
            });
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                u_mobile: '',
                subsidiary_name: '',
                partner_name: '',
                mobile: '',
                start_time: '',
                end_time: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.repair.Partner.list, searchForm).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        list[i].identity_card_front = [list[i].identity_card_front];
                        list[i].identity_card_verso = [list[i].identity_card_verso];
                        if (list[i].business_license) {
                            list[i].business_license = [list[i].business_license];
                        }
                        if (list[i].gate_picture) {
                            list[i].gate_picture = [list[i].gate_picture];
                        }
                        if (list[i].interior_picture) {
                            list[i].interior_picture = [list[i].interior_picture];
                        }
                    }
                    // console.log(list);
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        add () {
            this.type = 0;
            this.showAdd = !0;
            this.addForm = {
                user_id: '',
                type: 1,
                subsidiary_id: '',
                partner_name: '',
                mobile: '',
                identity_card_front: '',
                identity_card_verso: '',
                province_id: '',
                city_id: '',
                area_id: '',
                address: '',
                business_license: '',
                is_shop: -1,
                gate_picture: '',
                interior_picture: '',
            };
            this.province_name = '';
            this.cityArr = [];
            this.city_name = '';
            this.areaArr = [];
            this.area_name = '';
        },
        edit (row) {
            this.type = 1;
            this.showAdd = !0;
            this.addFrom = matchDataAssign(this.addForm, row);
            this.id = row.id;
            this.province_name = this.area.find(item => item.id == this.addFrom.province_id).name;
            this.cityArr = this.area.find(item => item.id == this.addFrom.province_id)._child;
            this.city_name = this.cityArr.find(item => item.id == this.addFrom.city_id).name;
            this.areaArr = this.cityArr.find(item => item.id == this.addFrom.city_id)._child;
            this.area_name = this.areaArr.find(item => item.id == this.addFrom.area_id).name;
        },
        // 三级联动选择地区
        sonArea (status, val) {
            if (status == 0) {
                let index = this.area.find(item => item.name == val);
                this.addForm.province_id = index.id;
                this.addForm.city_id = null;
                this.cityArr = index._child;
                this.city_name = null;
                this.addForm.area_id = null;
                this.areaArr = [];
                this.area_name = null;
            } else if (status == 1) {
                let index = this.cityArr.find(item => item.name == val);
                this.addForm.city_id = index.id;
                this.city_name = index.name;
                this.addForm.area_id = null;
                this.areaArr = index._child;
                this.area_name = null;
            } else if (status == 2) {
                let index = this.areaArr.find(item => item.name == val);
                this.addForm.area_id = index.id;
            }
        },
        // 调用接口时检测数据是否合法
        checkDataLegitimacy () {
            if (!this.addForm.user_id) {
                this.$message.warning('请选择用户');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.subsidiary_id) {
                this.$message.warning('请选择子公司');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.partner_name) {
                this.$message.warning('请填写合伙人姓名');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.mobile) {
                this.$message.warning('请填写联系电话');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.identity_card_front) {
                this.$message.warning('请上传身份证正面照');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.identity_card_verso) {
                this.$message.warning('请上传身份证背面照');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.province_id || !this.addForm.city_id || !this.addForm.area_id) {
                this.$message.warning('请选择合伙人区域');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.address) {
                this.$message.warning('请填写所在地址');
                this.activeTabs = 'one';
                return false;
            }
            if (this.addForm.type == 2 && !this.addForm.business_license) {
                this.$message.warning('请上传营业执照');
                this.activeTabs = 'one';
                return false;
            }
            if (this.addForm.is_shop == 1 && !this.addForm.gate_picture) {
                this.$message.warning('请上传门头照片');
                this.activeTabs = 'one';
                return false;
            }
            if (this.addForm.is_shop == 1 && !this.addForm.interior_picture) {
                this.$message.warning('请上传店内照片');
                this.activeTabs = 'one';
                return false;
            }
        },
        addSubmit () {
            let checkRes = this.checkDataLegitimacy();
            if (checkRes === false) return;
            let url = this.type ? this.$api.repair.Partner.edit : this.$api.repair.Partner.add;
            let data = {
                user_id: this.addForm.user_id,
                type: this.addForm.type,
                subsidiary_id: this.addForm.subsidiary_id,
                partner_name: this.addForm.partner_name,
                mobile: this.addForm.mobile,
                identity_card_front: this.addForm.identity_card_front,
                identity_card_verso: this.addForm.identity_card_verso,
                province_id: this.addForm.province_id,
                city_id: this.addForm.city_id,
                area_id: this.addForm.area_id,
                address: this.addForm.address,
                business_license: this.addForm.business_license,
                is_shop: this.addForm.is_shop,
                gate_picture: this.addForm.gate_picture,
                interior_picture: this.addForm.interior_picture,
            };
            if (this.type) data.id = this.id;
            this.$axios.post(url, data).then(res => {
                if (res.code == 0) {
                    this.showAdd = !1;
                    this.getList();
                    this.$message({
                        message: `${this.type ? '编辑成功' : '添加成功'}`,
                        type: 'success',
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        remove (id, index) {
            this.$confirm('确认删除此合伙人？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.repair.Partner.del, {
                        id: id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                            this.list.splice(index, 1);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
    },
};
</script>

<style lang="less" scoped>.selectBox {
    display: flex;

    .el-select {
        flex: 1;

        /deep/ .el-input {
            width: 100% !important;
        }
    }
}

.el-main {
    background: #fff;
}

.el-form-search {
    margin-bottom: 25px;
}</style>
